import Vue from 'vue';
import { filtrarAtributosNulosObjeto } from '../helpers/Utilitarios';
export default class WhatsappService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    filtrarCampanhas(filtros, nr_pagina, nr_email_pagina) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.apresentante = this._store.getters.cdApresentante;
        filtros.pagina = nr_pagina;
        filtros.email_por_pagina = nr_email_pagina;

        return Vue.http.get(url + '/whatsapp/api/whatsapp/', {
            params: filtros
        });
    }

    relatorioCampanhas(filtros, limit = null, offset = null) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.limit = limit;
        filtros.offset = offset;
        filtrarAtributosNulosObjeto(filtros);

        return Vue.http.get(
            url +
                `/whatsapp/api/whatsapp/report/?environment=RECUPERI&apresentante=${this._store.getters.cdApresentante}`,
            {
                params: filtros
            }
        );
    }

    detalharCampanha(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(
            url + `/whatsapp/api/whatsapp/?id=${campanha.id}&`,
            {
                params: {
                    apresentante: this._store.getters.cdApresentante
                }
            }
        );
    }

    verificaTitulosVinculados(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(
            url +
                `/whatsapp/api/whatsapp-title/?environment=RECUPERI&apresentante=${this._store.getters.cdApresentante}&whatsapp=${campanha.id}`,
            {
                params: {
                    apresentante: this._store.getters.cdApresentante
                }
            }
        );
    }

    salvarCampanha(data) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/whatsapp/api/whatsapp/';
        if (data.id) {
            let payload = {
                id: data.id,
                is_active: data.is_active === true ? 'true' : 'false',
                created_by_title: data.created_by_title
            };
            response = Vue.http.patch(url, payload);
        } else {
            response = Vue.http.post(url, data);
        }
        return response;
    }

    salvarContato(data, campanhaCriada) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        let payload = {
            name: data.name,
            phone: data.phone,
            whatsapp: campanhaCriada
        };
        url = url + '/whatsapp/api/contacts/';
        response = Vue.http.post(url, payload);
        return response;
    }

    salvarContatosTitulos(campanhaCriada, titulosSelecionados) {
        var response = null;
        let payload = {
            whatsapp: campanhaCriada,
            title_ids: titulosSelecionados
        };
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/whatsapp/api/whatsapp-title/?';
        response = Vue.http.post(url, payload);
        return response;
    }

    envioTeste(numero, idCampanha, name) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        let payload = {
            campaign: idCampanha,
            phone: numero,
            name: name
        };
        url = url + '/whatsapp/api/send-whatsapp-campaign/';
        response = Vue.http.post(url, payload);
        return response;
    }

    listarContatos(campanha) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return Vue.http.get(
            url +
                '/whatsapp/api/contacts/' +
                '?&whatsapp=' +
                campanha.id +
                '&environment=RECUPERI' +
                '&apresentante=' +
                this._store.getters.cdApresentante
        );
    }

    verificaStatus() {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return Vue.http.get(
            url +
                '/whatsapp/api/whatsapp-template/?environment=RECUPERI&is_active=true'
        );
    }
}
