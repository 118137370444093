<template>
    <div>
        <!-- Cabeçalho -->
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-whatsapp</v-icon
                    >Enviar Whatsapp > Listar Campanhas
                </v-card-text>
            </v-card>
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="colorSnackbar"
                :mensagemSnackbar="mensagemSnackbar"
                @fecharSnackbar="snackbar = false"
            />

            <v-card
                class="my-3 pa-3"
                color="primary"
                style="border-radius: 20px;"
            >
                <v-row class="py-8">
                    <v-col cols="3" xs="3" md="3" class="ma-3 pa-3">
                        <v-icon
                            class="white--text ma-3 pa-3 mx-auto my-auto"
                            size="110"
                            elevation-8
                        >
                            mdi-whatsapp
                        </v-icon>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="5"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text class="white--text display-1">
                            Gerenciar Campanhas de <strong>Whatsapp</strong>
                            <v-chip
                                class="ma-2"
                                color="secondary"
                                label
                                text-color="white"
                            >
                                <v-icon left>
                                    mdi-label
                                </v-icon>
                                Novidade!
                            </v-chip>
                        </v-card-text>
                    </v-col>
                    <v-col
                        sm="12"
                        md="2"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text
                            class="
                                white--text
                                font-weight-light
                                subtitle
                                mb-3
                                pa-3
                            "
                        >
                            Crie e gerencie envio de Whatsapp para campanhas na
                            Intima Digital.
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card class="mt-sm-n8 ma-6" elevation-0 outlined>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="grey lighten-4">
                                <v-card-text
                                    class="font-weight-light black--text"
                                >
                                    <v-icon class="primary--text lighten-2">
                                        search
                                    </v-icon>
                                    Filtrar Resultados
                                </v-card-text>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="my-4 ml-4">
                                    <v-col
                                        cols="10"
                                        xs="2"
                                        sm="2"
                                        md="4"
                                        lg="4"
                                        class="mx-2"
                                    >
                                        <v-text-field
                                            solo
                                            hint="Informe o Nome da campanha"
                                            label="Nome da Campanha"
                                            splaceholder="Nome da Campanha"
                                            v-model="filtros.campaign_name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        xs="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-menu
                                            ref="menu1"
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        computedDateFormattedInicio
                                                    "
                                                    label="Data Inicial"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @change="limparDataInicio"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="filtros.start_date"
                                                no-title
                                                scrollable
                                                locale="pt-br"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu1 = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.menu1.save(
                                                            whatsapp.start_date
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span>
                                            Utilize esse campo para filtrar
                                            buscando a data Inicial</span
                                        >
                                    </v-tooltip>
                                    <v-col
                                        cols="4"
                                        xs="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-menu
                                            ref="menu2"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        computedDateFormattedFim
                                                    "
                                                    label="Data Final"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @change="limparDataFim"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="filtros.end_date"
                                                no-title
                                                scrollable
                                                locale="pt-br"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu1 = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.menu2.save(
                                                            filtros.end_date_fmt
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span></span>
                                    </v-tooltip>
                                    <v-col
                                        cols="10"
                                        xs="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-select
                                            v-model="filtros.status"
                                            label="Status"
                                            item-text="name"
                                            item-value="value"
                                            :return-object="true"
                                            solo
                                            :items="[
                                                { name: 'Todos', value: null },
                                                { name: 'Ativo', value: true },
                                                {
                                                    name: 'Inativos',
                                                    value: false
                                                }
                                            ]"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span></span>
                                    </v-tooltip>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-card>

            <v-card @keyup.native.enter="aplicarFiltros" class="pa-3 mb-3">
                <v-row class="py-4 px-9">
                    <v-spacer />
                    <v-btn
                        width="180"
                        class="ml-3 justify-center"
                        color="primary"
                        :loading="loadingFiltros"
                        @click="botaoFiltrar"
                    >
                        Listar
                        <v-icon class="ml-2">search</v-icon>
                    </v-btn>
                    <v-btn
                        class="ml-3 justify-center"
                        color="green white--text"
                        @click="criarCampanhaTitulo"
                        name="criarWhatsapp"
                    >
                        Campanha Títulos
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                </v-row>
            </v-card>

            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <v-data-table
                :headers="headers"
                :items="whatsappitems"
                item-key="ofertas"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Itens por página:'
                }"
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id`]="{ item }">
                    <span>
                        {{ item.id }}
                    </span>
                </template>
                <template v-slot:[`item.processing_status`]="{ item }">
                    <span v-if="item.processing_status == 'F'">
                        Finalizado
                    </span>
                    <span v-if="item.processing_status == 'P'">
                        Processando
                    </span>
                    <span v-if="item.processing_status == 'W'">
                        Pendente
                    </span>
                </template>
                <template v-slot:[`item.time_stamp`]="{ item }">
                    <span v-if="item.time_stamp == true">
                        Sim
                    </span>
                    <span v-if="item.time_stamp == false">
                        Não
                    </span>
                </template>
                <template v-slot:[`item.title`]="{ item }">
                    <span>
                        {{ item.title }}
                    </span>
                </template>
                <template v-slot:[`item.is_active`]="{ item }">
                    <v-switch
                        :color="item.is_active != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.is_active"
                        inset
                        :style="{
                            color: item.is_active != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    {{
                        formatarDataHoraMoment(
                            item.created_at,
                            'DD/MM/YYYY HH:mm'
                        )
                    }}
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{
                        formatarDataHoraMoment(
                            item.updated_at,
                            'DD/MM/YYYY HH:mm'
                        )
                    }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        elevation="1"
                        width="100%"
                        :loading="loading"
                        @click="exibirDetalhe(item)"
                        class="mr-2 grey white--text"
                        small
                    >
                        <v-icon
                            small
                            class="mr-2"
                            @click="exibirDetalhe(item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Detalhes
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre a Campanha -->
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            {{ tituloDetalheCampanha }}
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <DetalheCampanha
                        :campanha="campanha"
                        :titulosCampanha="titulosCampanha"
                        :validarForm="validarForm"
                        @setIsInvalid="isInvalid = $event"
                        @formValidado="validarForm = $event"
                        @fecharModal="closeDialog"
                    />
                </v-card-text>
                <v-divider />
            </v-card>
            <!-- fim das informações sobre a campanha --------------------- -->
        </v-dialog>
        <v-dialog
            v-model="dialogUpload"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o upload de whatsapp -->
            <validation-observer ref="whatsappUpload" v-slot="{ invalid }">
                <form ref="WhatsappUploadForm">
                    <v-card class="ml-1">
                        <v-toolbar flat dark color="primary">
                            <v-btn icon dark @click="closeDialogUpload">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-col cols="12" sm="2" align="left">
                                <v-toolbar-title
                                    >Upload Whatsapp</v-toolbar-title
                                >
                            </v-col>
                        </v-toolbar>
                        <v-card-text>
                            <DetalheUploadWhatsapp
                                :detalheWhatsapp.sync="whatsapp"
                            />
                        </v-card-text>
                        <v-container>
                            <v-row class="pa-2" align="baseline" justify="end">
                                <v-btn
                                    class="ml-5"
                                    color="green white--text"
                                    :disabled="invalid"
                                    @click="closeDialogUpload"
                                >
                                    Fechar
                                </v-btn>
                                <v-icon class="ml-2"></v-icon>
                            </v-row>
                        </v-container>
                    </v-card>
                </form>
            </validation-observer>
            <!-- fim das informações sobre o upload de whatsapp --------------------- -->
        </v-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import * as Formatter from '@/helpers/Formatter';
import NotificaWhatsappService from '@/services/NotificaWhatsappService';
import Whatsapp from '@/domains/Whatsapp';
import WhatsappUpload from '@/domains/WhatsappUpload';
import DetalheCampanha from '@/components/DetalheCampanhaWhatsapp';
import DetalheUploadWhatsapp from '@/components/DetalheUploadWhatsapp';
import { required, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import {
    formatarDataHoraMoment
} from '@/helpers/Utilitarios';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

export default {
    name: 'ListarWhatsapp',
    mixins: [validationMixin],
    props: {
        titulosCampanha: {
            type: Object,
            required: false
        }
    },
    components: {
        DetalheCampanha,
        DetalheUploadWhatsapp,
        ValidationObserver
    },
    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },
    data() {
        return {
            loading: false,
            isInvalid: false,
            validarForm: false,
            tituloDetalheWhatsapp: '',
            whatsappitems: [],
            filtros: {
                title: null,
                id: null,
                destinatario: null,
                status: false,
                titulo: null,
                mensagem: null,
                start_date: null,
                end_date: null
            },
            dialog: false,
            dialogUpload: false,
            loadingFiltros: null,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            id: null,
            is_active: null,
            ultimoFiltro: null,
            status: null,
            pagina: 1,
            totalWhatsapp: 0,
            showPagination: false,
            whatsapp: new Whatsapp(),
            uploadWhatsapp: new WhatsappUpload(),
            snackbar: false,
            snackbarColor: 'green white--text',
            mensagem: null,
            mensagemAviso: null,
            headers: [
                {
                    text: 'Status',
                    sortable: false,
                    value: 'is_active'
                },
                {
                    text: 'Nome',
                    sortable: false,
                    value: 'campaign_name'
                },
                {
                    text: 'Url',
                    sortable: false,
                    value: 'company_url'
                },
                {
                    text: 'Status de Processamento',
                    sortable: false,
                    value: 'processing_status'
                },
                {
                    text: 'Carimbo',
                    sortable: false,
                    value: 'time_stamp'
                },
                {
                    text: 'Criação',
                    sortable: false,
                    value: 'created_at',
                    dataType: 'Date'
                },
                {
                    text: 'Atualização',
                    sortable: false,
                    value: 'updated_at',
                    dataType: 'Date'
                },
                {
                    text: 'Contatos',
                    sortable: false,
                    value: 'all_contacts'
                },
                {
                    text: 'Enviados',
                    sortable: false,
                    value: 'sent_contacts'
                },
                {
                    text: 'Falhas',
                    sortable: false,
                    value: 'failed_contacts'
                },

                { text: ' ', sortable: false, value: 'action' },
                { text: ' ', sortable: false, value: 'action2' }
            ],
            mensagemSnackbar: null,
            colorSnackbar: null,
            tituloDetalheCampanha: null,
            campanha: null
        };
    },

    computed: {
        checkboxErrors() {
            const errors = [];
            if (!this.$v.checkbox.$dirty) return errors;
            !this.$v.checkbox.checked &&
                errors.push('You must agree to continue!');
            return errors;
        },

        selectErrors() {
            const errors = [];
            if (!this.$v.select.$dirty) return errors;
            !this.$v.select.required && errors.push('Item is required');
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength &&
                errors.push('Name must be at most 10 characters long');
            !this.$v.name.required && errors.push('Name is required.');
            return errors;
        },
        whatsappErrors() {
            const errors = [];
            if (!this.$v.whatsapp.$dirty) return errors;
            !this.$v.whatsapp.whatsapp && errors.push('Must be valid e-mail');
            !this.$v.whatsapp.required && errors.push('E-mail is required');
            return errors;
        },
        computedDateFormattedInicio: {
            get: function() {
                if (this.filtros.start_date) {
                    return this.formatDate(this.filtros.start_date);
                } else {
                    return this.formatDate(this.start_date);
                }
            },
            set: function(value) {
                this.filtros.start_date = value;
            }
        },
        computedDateFormattedFim: {
            get: function() {
                if (this.filtros.end_date) {
                    return this.formatDate(this.filtros.end_date);
                } else {
                    return this.formatDate(this.end_date);
                }
            },
            set: function(value) {
                this.filtros.end_date = value;
            }
        }
    },
    methods: {
        criarCampanhaTitulo() {
            this.campanha = new Whatsapp();
            this.campanha.is_active = true;
            this.campanha.created_by_title = true;
            this.campanha.status = [];
            this.tituloDetalheCampanha = 'Criar Campanha Whatsapp';
            this.dialog = true;
            this.dialogLoading = false;
        },
        formatarDataHoraMoment,
        exibirSnackbar(colorSnackbar, mensagemSnackbar) {
            this.snackbar = true;
            this.colorSnackbar = colorSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        botaoFiltrar() {
            if (this.pagina == 1) {
                this.aplicarFiltros();
            } else {
                this.pagina = 1;
            }
        },
        dataInicio() {
            this.filtros.start_date = [
                Formatter.formatData(this.filtros.start_date)
            ];
        },
        dataFim() {
            this.filtros.end_date = [
                Formatter.formatData(this.filtros.end_date)
            ];
        },
        salvar() {
            this.$emit('actionSalvar');
        },

        closeDialog() {
            this.dialog = false;
        },
        closeDialogUpload() {
            this.dialogUpload = false;
        },
        limparDataInicio() {
            this.whatsapp.start_date = [];
        },
        limparDataFim() {
            this.whatsapp.end_date = [];
        },
        formatDate(date) {
            if (!date) return null;
            if (date.includes('-')) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        formatHour(date) {
            if (!date) return null;
            if (date.toString().includes(':')) {
                const [hours, minutes, seconds] = date
                    .toString()
                    .split('T')[1]
                    .split('-')[0]
                    .split(':');
                return `${hours}:${minutes}:${seconds}`;
            } else {
                return date;
            }
        },
        aplicarFiltros() {
            this.ultimoFiltro = {};
            if (this.filtros.id) {
                this.ultimoFiltro.id = this.filtros.id;
            }
            if (this.filtros.campaign_name) {
                this.ultimoFiltro.campaign_name = this.filtros.campaign_name;
            }
            if (this.filtros.status) {
                this.ultimoFiltro.status = this.filtros.status.value;
            }
            if (this.filtros.start_date) {
                this.ultimoFiltro.start_date = this.filtros.start_date;
            }
            if (this.filtros.end_date) {
                this.ultimoFiltro.end_date = this.filtros.end_date;
            }
            this.buscarWhatsapp().then(data => {
                this.whatsapp = data.whatsapp;
                this.totalWhatsapp = data.total;
                if (data.total === 0) {
                    this.mensagemAviso =
                        'A Consulta não retornou nenhum titulo.';
                    this.numero_paginas = 1;
                } else {
                    this.mensagemAviso = null;
                    this.exibirSnackbar('error', 'Falha');
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
            });
        },
        buscarWhatsapp() {
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    let service = new NotificaWhatsappService(
                        Vue.http,
                        this.$store
                    );

                    service
                        .filtrarWhatsapp(
                            this.ultimoFiltro,
                            this.pagina,
                            this.registros_pagina
                        )
                        .then(
                            response => {
                                if (response.status == 200) {
                                    this.whatsappitems = [];
                                    var whatsappitems = response.data.results;
                                    whatsappitems.forEach(element => {
                                        const startDate = element.start_date;
                                        if (startDate) {
                                            element.start_date = Formatter.formatData(
                                                startDate.split('T')[0]
                                            );
                                            element.start_hour = Formatter.formatHour(
                                                startDate.split('T')[1]
                                            );
                                        }
                                        const endDate = element.end_date;
                                        if (endDate) {
                                            element.end_date = Formatter.formatData(
                                                endDate.split('T')[0]
                                            );
                                            element.end_hour = Formatter.formatHour(
                                                endDate.split('T')[1]
                                            );
                                        }
                                        this.whatsappitems.push(element);
                                    });
                                    this.loadingFiltros = false;
                                    this.loading = false;
                                    this.exibirSnackbar(
                                        'success',
                                        'Consulta realizada com sucesso.'
                                    );
                                } else {
                                    this.exibirSnackbar(
                                        'red',
                                        'Falha na solicitação'
                                    );
                                }
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                console.log(error.body.mensagem);
                                this.exibirSnackbar('red', error.body.mensagem);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        exibirDetalhe(whatsapp) {
            this.loading = true;
            this.campanha = new Whatsapp();
            let whatsappService = new NotificaWhatsappService(
                Vue.http,
                this.$store
            );
            whatsappService.detalheCampanhaWhatsapp(whatsapp).then(
                response => {
                    this.campanha = response.body.results[0];
                    this.tituloDetalheWhatsapp =
                        'Visualizar/Editar Template Whatsapp';
                    this.dialog = true;
                    this.loading = false;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagemAviso = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        criarUploadWhatsapp(whatsapp) {
            this.whatsapp = whatsapp;
            this.dialogUpload = true;
            this.dialogUploadLoading = false;
            this.$emit('update:dialogUpload', this.DetalheWhatsapp);
        }
    },
};
</script>
