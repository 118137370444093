<template>
    <div>
        <div v-if="!templateTitulos && !templateContatos">
            <v-row class="d-flex text-center">
                <v-col cols="6" sm="12" md="12" justify-center
                    ><v-card-title
                        class="primary--text font-weight-black justify-center title-2"
                    >
                        Para definir quais contatos irão receber as mensagens,
                        selecione uma opção abaixo:</v-card-title
                    >
                    <v-card-text
                        >Você poderá optar por selecionar os contatos com base
                        em títulos da base Recuperi (selecionando por exemplo:
                        Protestados, Cancelados, Enviados) ou também poderá
                        fazer o upload de um arquivo XLS com os contatos que
                        deseja acionar</v-card-text
                    >
                </v-col>
                <v-col lg="3"></v-col>
                <v-col lg="3">
                    <v-card>
                        <v-icon color="primary" size="300%" class="mt-5">
                            mdi-message-arrow-right
                        </v-icon>
                        <v-card-text class="title">
                            Enviar para todos os Títulos da Campanha com base no
                            Status Selecionado
                        </v-card-text>
                        <v-card-text style="min-height:130px">
                            Essa campanha já possui um template definido. Para
                            ver os detalhes acesse a campanha na tela anterior.
                        </v-card-text>
                        <v-btn
                            @click="ativaTitulos"
                            :loading="loading"
                            class="primary white--text mb-10"
                        >
                            <v-icon color="white">
                                mdi-chevron-right
                            </v-icon>
                            Títulos da Campanha
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col lg="3">
                    <v-card>
                        <v-icon color="primary" size="300%" class="mt-5">
                            mdi-file-upload-outline
                        </v-icon>
                        <v-card-text class="title">
                            Fazer Upload de um arquivo XLS com contatos para a
                            Campanha Selecionada
                        </v-card-text>
                        <v-card-text style="min-height:130px">
                            Essa campanha já possui um template definido. Você
                            irá definir aqui quais os contatos que irão receber
                            a mensagem.
                        </v-card-text>
                        <v-btn
                            @click="templateContatos = true"
                            :loading="loading"
                            class="primary white--text mb-10"
                        >
                            <v-icon color="white">
                                mdi-chevron-right
                            </v-icon>
                            Fazer Upload
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col lg="3"></v-col>
            </v-row>
        </div>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="'success white--text'"
            :mensagemSnackbar="snackbarDesc"
            @fecharSnackbar="snackbar = false"
        />        
        <div v-if="templateTitulos">
            <v-container justify="center">
                <v-row class="d-flex text-center">
                    <v-col lg="3"></v-col>
                    <v-col lg="6">
                        <v-card>
                            <v-icon color="primary" size="500%" class="mt-5">
                                mdi-bookmark-check
                            </v-icon>
                            <v-card-text class="title primary--text">
                                Campanha configurada com sucesso!
                            </v-card-text>
                            <v-card-text class="title">
                                <strong>Tipo de envio: </strong>
                            </v-card-text>
                            <v-card-text class="title">
                                contatos vinculados aos títulos da base
                                Recuperi, Template selecionado na Campanha.
                            </v-card-text>
                            <v-card-text style="min-height:130px">
                                O envio de mensagens para essa campanha está
                                configurado! Poderá acompanhar o andamento das
                                mensagens através dos nossos relatórios.
                            </v-card-text>
                            <v-btn
                                disabled
                                :loading="loading"
                                class="primary white--text mb-10"
                            >
                                <v-icon color="white">
                                    mdi-chevron-right
                                </v-icon>
                                Acompanhar Envios
                            </v-btn>
                        </v-card>
                    </v-col>
                    <v-col lg="3"></v-col>
                </v-row>
            </v-container>
        </div>
        <div v-if="templateContatos">
            <v-card>
                <v-container justify="center">
                    <v-alert
                        v-if="contatosAtivos"
                        dense
                        type="info"
                        color="primary"
                    >
                        Importante: Essa campanha já possui contatos vinculados
                        via Upload de arquivo XLS, por isso, agora você poderá
                        incluir contatos apenas via Upload de contatos com
                        arquivo XLS. Caso queira criar uma campanha com base em
                        títulos/status, recomendamos criar uma nova campanha :)
                    </v-alert>
                    <v-card-title
                        class="primary--text font-weight-black justify-center title-2"
                    >
                        Faça Upload dos contatos para envio das mensagems
                        Whatsapp:</v-card-title
                    >
                    <v-card
                        color="primary"
                        class="ma-3 pa-3"
                        elevation="3"
                        style="border-radius:20px"
                    >
                        <v-row class="ma-3 pa-3">
                            <v-col cols="3" sm="2" class="">
                                <v-icon color="white" size="1000%">
                                    mdi-whatsapp
                                </v-icon>
                            </v-col>
                            <v-col cols="4" sm="10">
                                <v-card-text
                                    class="display-1 mx-auto white--text justify-center font-weight-black"
                                    >Faça Upload do Arquivo XLS com os
                                    contatos</v-card-text
                                >
                                <v-card-text
                                    class="subtitle-1 mx-auto white--text justify-center font-weight-normal"
                                    >Utilize o template disponível no botão
                                    abaixo para fazer upload dos
                                    contatos.</v-card-text
                                >
                            </v-col>
                            <v-row>
                                <v-col cols="3" sm="12" class="mt-2">
                                    <v-card class="grey lighten-4 pa-3">
                                        <v-file-input
                                            class="mb-n7 white--text"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                                            label="Selecione o arquivo (.xls/.xlsx/ .xlsm)"
                                            show-size
                                            :loading="loadingUpload"
                                            :disabled="desabilitaBoxEnvio"
                                            chips
                                            outlined
                                            ref="myFileInput"
                                            id="fileInput"
                                            name="file"
                                            @change="uploadFileReference"
                                        ></v-file-input>
                                    </v-card>
                                </v-col>
                                <v-col
                                    cols="3"
                                    sm="3"
                                    md="12"
                                    class="pa-3"
                                    align="right"
                                >
                                    <v-btn
                                        class="green white--text"
                                        large
                                        :loading="loadingUpload"
                                        :disabled="uploadOk"
                                        @click="upload"
                                    >
                                        enviar
                                        <v-icon class="ml-2"
                                            >cloud_upload</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        color="green white--text"
                                        class="mx-3"
                                        large
                                        :href="linkModelo"
                                    >
                                        baixar template
                                        <v-icon class="ml-2"
                                            >cloud_download</v-icon
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-card>
                    <v-row>
                        <v-col cols="6" sm="4" md="12" justify-center>
                            <v-card-title
                                class="primary--text font-weight-black justify-center title-2"
                            >
                                <v-icon color="primary"> expand_more </v-icon>
                                Status do Upload:</v-card-title
                            >
                        </v-col>
                    </v-row>
                    <v-row class="mx-6 my-0 px-6 pb-6">
                        <v-col cols="6" sm="4" md="4">
                            <v-card class="ma-3 pa-3 grey lighten-4">
                                <v-card-text
                                    class="grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                >
                                    Situação atual do envio:
                                </v-card-text>
                                <v-card-text
                                    class="primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                >
                                    A Enviar
                                </v-card-text>
                                <v-card-text
                                    class="grey--text display-2 font-weight-black text-center"
                                >
                                    {{ customerCountRead }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" md="4">
                            <v-card class="ma-3 pa-3 grey lighten-4">
                                <v-card-text
                                    class="grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                >
                                    Situação atual do envio:
                                </v-card-text>
                                <v-card-text
                                    class="primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                >
                                    Enviados
                                </v-card-text>
                                <v-card-text
                                    class="grey--text display-2 font-weight-black text-center"
                                >
                                    {{ customerCount }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" md="4">
                            <v-card class="ma-3 pa-3 grey lighten-4">
                                <v-card-text
                                    class="grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                >
                                    Situação atual do envio:
                                </v-card-text>
                                <v-card-text
                                    class="primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                >
                                    Erro
                                </v-card-text>
                                <v-card-text
                                    class="grey--text display-2 font-weight-black text-center"
                                >
                                    {{ customerCountError }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <div>
                            <v-col cols="6" sm="4" md="12" justify-center
                                ><v-card-title
                                    class="primary--text font-weight-black justify-center title-2"
                                >
                                    Contatos ativos para essa
                                    campanha:</v-card-title
                                ></v-col
                            >
                            <v-data-table
                                :headers="headers"
                                :items="contacts"
                                :options.sync="options"
                                :loading="loading"
                                :footer-props="{
                                    itemsPerPageOptions: [50, 100, 500]
                                }"
                                loading-text="Atualizando..."
                                no-data-text="Não há resultados..."
                                no-results-text="Nenhum título encontrado..."
                                class="elevation-1 pa-3"
                            >
                                <template
                                    v-slot:[`item.enviado`]="{
                                        item
                                    }"
                                >
                                    <v-icon
                                        :style="{
                                            color:
                                                item.enviado != ''
                                                    ? 'green'
                                                    : 'red'
                                        }"
                                        >{{ getBolean(item.enviado) }}</v-icon
                                    >
                                </template>
                                <template
                                    v-slot:[`item.status_envio`]="{
                                        item
                                    }"
                                >
                                    <v-icon
                                        :style="{
                                            color:
                                                item.status_envio != ''
                                                    ? 'green'
                                                    : 'red'
                                        }"
                                        >{{
                                            getBolean(item.status_envio)
                                        }}</v-icon
                                    >
                                </template>
                            </v-data-table>
                            <v-col cols="6" sm="4" md="12" justify-center
                                ><v-card-title
                                    class="primary--text font-weight-black justify-center title-2"
                                >
                                    Importante:</v-card-title
                                >
                                <v-card-title
                                    class="grey--text justify-center subtitle-2"
                                >
                                    Após concluir o upload, verifique se o
                                    status de cada contato está ativo, se
                                    estiver ativo na listagem ele está apto a
                                    receber uma mensagem Whatsapp da
                                    Recuperi.</v-card-title
                                ></v-col
                            >
                        </div>
                    </v-row>
                </v-container>
            </v-card>
        </div>
    </div>
</template>

<script>
//import * as Formatter from '@/helpers/Formatter';
import Vue from 'vue';
import { setInteractionMode } from 'vee-validate';
import WhatsappService from '@/services/WhatsappService';
import XLSX from 'xlsx';
import { eventFecharModal } from '../helpers/Utilitarios';

setInteractionMode('eager');

export default {
    name: 'DetalheUploadWhatsapp',
    props: {
        campanha: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        nomeTemplate: null,
        snackbar: false,
        snackbarDesc: null,
        contatosAtivos: false,
        dadosCampanha: null,
        templateTitulos: false,
        templateContatos: false,
        maxFileSize: 60000000,
        rules: [
            value =>
                !value ||
                (this.checkfile(value) && this.checkSize(value)) ||
                'O arquivo deve possuir menos de 60 MB e ser do tipo .xls/.xlsx!'
        ],
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
        value: 'Esse é um SMS exemplo, onde informa os detalhes da oferta',
        errorMessages: null,
        linkModelo: '/recuperi-whatsapp.xlsx',
        loading: false,
        loadingUpload: false,
        uploadOk: false,
        mensagemAlerta: false,
        desabilitaBoxEnvio: false,
        file: null,
        customerCount: 0,
        customerCountError: 0,
        customerCountRead: 0,
        contacts: [],
        newContacts: [],
        options: {},
        headers: [
            {
                text: 'Id',
                sortable: false,
                value: 'id'
            },
            {
                text: 'Nome',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Telefone',
                align: 'left',
                sortable: false,
                value: 'phone'
            },
            {
                text: 'Enviado',
                sortable: false,
                value: 'sent'
            },
            {
                text: 'Atualizado em',
                sortable: false,
                value: 'updated_at'
            },
            {
                text: 'Valor',
                sortable: false,
                value: 'value'
            },
            {
                text: 'Valor da Dívida',
                sortable: false,
                value: 'document_value'
            },
            {
                text: 'Ativo',
                sortable: false,
                value: 'is_active'
            }
        ]
    }),
    methods: {
        async ativaTitulos() {
            let uploadService = new WhatsappService(Vue.http, this.$store);
            this.loading = true;
            let campanha = this.dadosCampanha;
            campanha.created_by_title = true;
            await uploadService.salvarCampanha(campanha).then(
                response => {
                    this.loading = false;
                    console.log(response);
                    this.templateTitulos = true;
                },
                error => {
                    console.log(error);
                }
            );
        },
        eventFecharModal,
        cleanFields() {
            this.$refs.myFileInput.reset();
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        submit() {
            this.$refs.observer.validate();
        },
        checkfile(sender) {
            var validExts = new Array(
                '.xlsm',
                '.xlsx',
                '.xls',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            );
            var fileExt = sender.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.includes(fileExt)) {
                return true;
            } else return false;
        },
        checkSize(value) {
            return value.size < this.maxFileSize;
        },
        exibirDetalhe(campanha) {
            let whatsappService = new WhatsappService(Vue.http, this.$store);
            whatsappService.detalharCampanha(campanha).then(
                response => {
                    this.campanha = response.body.results[0];
                    console.log(this.campanha);
                    this.tituloDetalheCampanha = 'Editar Campanha WhatsApp';
                    this.dialog = true;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagemAviso = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        buscarContatos() {
            let uploadService = new WhatsappService(Vue.http, this.$store);
            let campanha = this.dadosCampanha;
            let createdByTitle = campanha.created_by_title;
            uploadService.listarContatos(campanha).then(
                response => {
                    this.contacts = response.data.results;
                    if (this.contacts.length > 0) {
                        this.templateContatos = true;
                        this.contatosAtivos = true;
                        this.loading = false;
                        this.snackbar = false;
                    }
                    if (createdByTitle === true) {
                        this.templateTitulos = true;
                        this.loading = false;
                        this.snackbar = false;
                    } else {
                        this.snackbar = true;
                        this.loading = false;
                        this.snackbarDesc =
                            'Importante: Essa campanha ainda não possui contatos vinculados, para iniciar selecione uma das opções de contatos acima. (Titulos da base Recuperi ou Upload de arquivo .Xls)';
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        uploadFileReference(e) {
            if (!e) return null;
            this.file = e;
            this.uploadOk = false;
            const reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, { type: 'binary' });
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet);
                const finalJsonData = JSON.stringify(rowObject, undefined, 4);
                let rows = JSON.parse(finalJsonData);
                rows.splice(0, 1);
                vm.customerCountRead = rows.length;
                vm.newContacts = rows;
                this.customerCountError = 0;
                this.customerCount = 0;
            };
            reader.readAsBinaryString(this.file);
        },
        getBolean(valor) {
            if (!valor || valor === true) {
                return 'done';
            } else {
                return 'close';
            }
        },
        upload() {
            if (!this.file) {
                this.tipoMensagem = `error`;
                this.mensagem = 'Selecione um arquivo!!!';
                return;
            } else if (!this.checkfile(this.file)) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo não é do tipo .xls/.xlsx/.xlsm';
            } else if (this.file.size > this.maxFileSize) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo possui mais buscarHistoricode 60 MB';
            } else {
                let whatsappService = new WhatsappService(
                    Vue.http,
                    this.$store
                );
                const campanhaP = this.campanha.id;
                for (var i in this.newContacts) {
                    var row = this.newContacts[i];
                    row.whatsapp = campanhaP;
                    if (row.document_value)
                        row.document_value = row.document_value.toFixed(2);
                    if (row.value) row.value = row.value.toFixed(2);
                    row.phone = row.phone.toString().replace(/\D/g, '');
                    whatsappService.salvarContato(row).then(
                        response => {
                            let data = response.data;
                            data.enviado = true;
                            let contactsResponse = [];
                            contactsResponse.push(data);
                            this.contacts = contactsResponse.concat(
                                this.contacts
                            );
                            this.customerCount += 1;
                        },
                        error => {
                            console.log(error);
                            row.enviado = false;
                            let contactsResponse = [];
                            contactsResponse.push(row);
                            this.contacts = contactsResponse.concat(
                                this.contacts
                            );
                            this.customerCountError += 1;
                        }
                    );
                }
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    async mounted() {
        this.loading = true;
        this.dadosCampanha = this.campanha;
        document.addEventListener('keyup', this.eventFecharModal);
        await this.buscarContatos();
    },
    computed: {
        getNomeCliente() {
            return 'Cliente Exemplo';
        }
    },
    watch: {
        detalheWhatsapp() {
            console.log('whatch');
            this.buscarContatos();
            this.cleanFields();
        }
    }
};
</script>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>
