export default class EmailUpload {
    constructor() {
        this.cd_apresentante = null;
        this.apresentante = null;
        this.nome = null;
        this.telefone = null;
        this.valor_titulo = null;
        this.valor_saldo_protestado = null;
        this.titulo_mensagem = null;
    }
}
